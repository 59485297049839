import React, { type FC, type CSSProperties } from 'react';
import { Loader } from '@/components/ui/Loader';
import {
  achievementsPageCSSVariables,
} from '@/components/platform/Achievements';
import { Subheader } from '@/components/platform/common/Subheader';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useAchievementsByCategoryWithSize } from '@/components/platform/Achievements/hooks';
import { AchievementsContainer } from '@/components/platform/Achievements/components/AchievementsContainer';
import { AchievementsPageHarnessSelectors } from '@/components/platform/Achievements/components/AchievementsPage/AchievementsPage.harness';

type Props = {
  userId: number;
};

export const Achievements: FC<Props> = ({
  userId,
}) => {
  const {
    achievementsByCategoryWithSize,
    achievementsCount,
    loading,
  } = useAchievementsByCategoryWithSize({
    userId,
  });

  const { t } = useTranslation([I18N_CODES.achievements]);

  if (achievementsCount === 0) {
    return null;
  }

  return (
    <div
      data-qa={AchievementsPageHarnessSelectors.DataQa}
      style={achievementsPageCSSVariables as CSSProperties}
    >
      <Subheader
        title={t(`${I18N_CODES.achievements}:achievements_header_title`)}
        withBackButton
      />

      {loading && (
        <Loader loading={loading} />
      )}

      <AchievementsContainer
        achievementsByCategoryWithSize={achievementsByCategoryWithSize}
      />
    </div>
  );
};
