import {
  AchievementIconLockedCircle,
  AchievementIconLockedHexagon,
  AchievementIconLockedShield,
  AchievementIconLockedShieldRotated,
  AchievementIconLockedShieldUp,
  AchievementIconLockedStar,
  AchievementIconUnknown,
} from '@/components/ui/icons/achievements';
import { CategoryBlockedReason, PersonalAchievementCategory } from '@/controllers/graphql/generated';
import {
  AchievementBlockedReasonTranslateKey,
  AchievementsCSSVariable,
  type AchievementsPageCSSVariables,
  CategorySize,
  type LockedAchievementIcons,
  LockedIconName,
  type PageCategoriesWithSize,
} from '@/components/platform/Achievements';

export const ACHIEVEMENT_ITEM_WIDTH = 120;
export const ACHIEVEMENT_ITEMS_SMALL_GAP = 32;
export const ACHIEVEMENT_ITEMS_LARGE_GAP = 50;
export const ACHIEVEMENT_COLS_COUNT_SMALL = 2;
export const ACHIEVEMENT_COLS_COUNT_MEDIUM = 3;
export const ACHIEVEMENT_COLS_COUNT_LARGE = 6;

export const achievementsPageCSSVariables: AchievementsPageCSSVariables = {
  [AchievementsCSSVariable.ColumnsCountSmall]: ACHIEVEMENT_COLS_COUNT_SMALL,
  [AchievementsCSSVariable.ColumnsCountMedium]: ACHIEVEMENT_COLS_COUNT_MEDIUM,
  [AchievementsCSSVariable.ColumnsCountLarge]: ACHIEVEMENT_COLS_COUNT_LARGE,
  [AchievementsCSSVariable.ItemWidth]: `${ACHIEVEMENT_ITEM_WIDTH}px`,
  [AchievementsCSSVariable.ItemGapSmall]: `${ACHIEVEMENT_ITEMS_SMALL_GAP}px`,
  [AchievementsCSSVariable.ItemGapLarge]: `${ACHIEVEMENT_ITEMS_LARGE_GAP}px`,
};

export const achievementItemCSSVariables
  : Pick<AchievementsPageCSSVariables, AchievementsCSSVariable.ItemWidth> = {
    [AchievementsCSSVariable.ItemWidth]: `${ACHIEVEMENT_ITEM_WIDTH}px`,
  };

export const maxItemsCount: Record<CategorySize, number> = {
  [CategorySize.Full]: ACHIEVEMENT_COLS_COUNT_LARGE,
  [CategorySize.Half]: ACHIEVEMENT_COLS_COUNT_MEDIUM,
};

export const AchievementIconLocked: LockedAchievementIcons = {
  [LockedIconName.Unknown]: AchievementIconUnknown,
  [LockedIconName.LockedCircle]: AchievementIconLockedCircle,
  [LockedIconName.LockedHexagon]: AchievementIconLockedHexagon,
  [LockedIconName.LockedShield]: AchievementIconLockedShield,
  [LockedIconName.LockedShieldUp]: AchievementIconLockedShieldUp,
  [LockedIconName.LockedStar]: AchievementIconLockedStar,
  [LockedIconName.LockedShieldRotated]: AchievementIconLockedShieldRotated,
};

export const ACHIEVEMENTS_CATEGORIES_ORDER_WITH_SIZE: PageCategoriesWithSize = [
  [PersonalAchievementCategory.Welcome, CategorySize.Full],
  [PersonalAchievementCategory.SubscriberWelcome, CategorySize.Half],
  [PersonalAchievementCategory.PowerfulStart, CategorySize.Half],
  [PersonalAchievementCategory.TypingSpeed, CategorySize.Half],
  [PersonalAchievementCategory.AutoTechChecks, CategorySize.Half],
  [PersonalAchievementCategory.DailyTasks, CategorySize.Half],
  [PersonalAchievementCategory.MorningTasks, CategorySize.Half],
  [PersonalAchievementCategory.LeaderboardBronze, CategorySize.Half],
  [PersonalAchievementCategory.LeaderboardSilver, CategorySize.Half],
  [PersonalAchievementCategory.LeaderboardGold, CategorySize.Half],
  [PersonalAchievementCategory.LeaderboardPlatinum, CategorySize.Half],
  [PersonalAchievementCategory.Events, CategorySize.Full],
  [PersonalAchievementCategory.CvMaster, CategorySize.Half],
  [PersonalAchievementCategory.InterviewNinja, CategorySize.Half],
  [PersonalAchievementCategory.ForumGuru, CategorySize.Full],
  [PersonalAchievementCategory.EnglishCourse, CategorySize.Half],
  [PersonalAchievementCategory.EnglishUpgrade, CategorySize.Half],
  [PersonalAchievementCategory.EnglishClub, CategorySize.Full],
  [PersonalAchievementCategory.QualityExpert, CategorySize.Full],
  [PersonalAchievementCategory.TeamPlayer, CategorySize.Half],
  [PersonalAchievementCategory.Punctuality, CategorySize.Half],
  [PersonalAchievementCategory.ChatGuru, CategorySize.Half],
  [PersonalAchievementCategory.DailyStreak, CategorySize.Full],
  [PersonalAchievementCategory.Certificates, CategorySize.Full],
];

export const blockedReasonTranslationKeys: Record<
  CategoryBlockedReason,
  AchievementBlockedReasonTranslateKey
> = {
  [CategoryBlockedReason.OnlyMateStudents]:
    AchievementBlockedReasonTranslateKey.OnlyMateStudents,
  [CategoryBlockedReason.OnlyFxStudents]:
    AchievementBlockedReasonTranslateKey.OnlyPTStudents,
  [CategoryBlockedReason.OnlyFtStudents]:
    AchievementBlockedReasonTranslateKey.OnlyFTStudents,
  [CategoryBlockedReason.UnderDevelopment]:
    AchievementBlockedReasonTranslateKey.UnderDevelopment,
};
